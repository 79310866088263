'use client';

import React from 'react';
import webAnimationData from '../../../public/lottie/loading_circle.json';
import appAnimationData from '../../../public/lottie/loading_character(typing).json';
import Lottie from 'react-lottie-player';
import styles from './ClientLoading.module.css';
import { useLoadingContext } from '../context/LoadingContext';
import { useAppContext } from '../context/AppContext';


interface LoadingProps {
  text? : string;
}

const ClientLoading: React.FC<LoadingProps> = ({ text }) => {
    const {isApp} = useAppContext();
    const {isLoading} = useLoadingContext();

    if(!isLoading) return null;
    
    return (
    <div className={styles.dim}>
      <div className={styles.loadingContainer}>
        <Lottie 
          animationData={isApp ? appAnimationData : webAnimationData} 
          loop 
          play
          style={{
            width : isApp ? 240 : 100, 
            height: isApp ? 240 : 100,
          }}
        />
        {isApp && <p className={styles.loadingText}>{text}</p> }
      </div>
    </div>
  );
};

export default ClientLoading;
